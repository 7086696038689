<template>
  <div class="w-full max-h-screen bg-background flex flex-col relative">
    <page-header title="FAQ's">
      <button @click="openCreateFaq()"
        class="flex items-center bg-green-200 border hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1">
        <FaqIcon />
        <span>Create FAQ</span>
      </button>
    </page-header>
    <main class="p-6 flex-1 overflow-y-scroll">
      <div class="w-full bg-white p-5 rounded shadow-md relative">
        <div class="w-full flex items-start justify-between">
          <div class="flex space-x-2">
            <button @click="selectTabAndClearSearch('new')"
              class="px-4 py-2 rounded transition-colors duration-300 flex items-center font-semibold" :class="currentTab == 'new'
                ? 'bg-orange-300 text-orange-900'
                : 'hover:bg-gray-200'
                ">
              <FaqIcon />
              <span>All FAQ's</span>
            </button>

            <button @click="currentTab = 'search'"
              class="px-4 py-2 rounded transition-colors duration-300 flex items-center font-semibold" :class="currentTab == 'search'
                ? 'bg-orange-300 text-orange-900'
                : 'hover:bg-gray-200'
                ">
              <svg class="stroke-current h-5 w-5 mr-2" fill="none" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" viewBox="0 0 24 24">
                <circle cx="11" cy="11" r="8" />
                <path d="M21 21l-4.35-4.35" />
              </svg>
              <span>Search</span>
            </button>
          </div>
          <div class="flex justify-end flex-1">
            <input type="text" class="ml-10 w-full lg:w-1/2 rounded-l bge-input bge-input-spacing"
              placeholder="Search faqs" v-model="searchTerm" @keydown.enter="search" />
            <button @click="clearFilter" v-if="hasSearched"
              class="rounded-r bg-blue-200 hover:bg-blue-300 border border-blue-400 hover:border-blue-500 p-2 hover:shadow active:shadow-inner transition-colors duration-300 ease-in-out">
              <svg class="stroke-current h-5 w-5" fill="none" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" viewBox="0 0 24 24">
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
            <div class="relative">
              <select class="bge-input bge-select rounded-none h-full" v-model="categoryFilter">
                <option value="">No Category</option>
                <optgroup label="Categories">
                  <option v-for="category in categories" :key="category" :value="category">{{ category }}</option>
                </optgroup>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>

            <button @click="search"
              class="rounded-r bg-blue-200 hover:bg-blue-300 border border-blue-400 hover:border-blue-500 p-2 hover:shadow active:shadow-inner transition-colors duration-300 ease-in-out">
              <svg class="stroke-current h-5 w-5" fill="none" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" viewBox="0 0 24 24">
                <circle cx="11" cy="11" r="8" />
                <path d="M21 21l-4.35-4.35" />
              </svg>
            </button>

          </div>
        </div>
        <div v-if="viewState == 'Idle'" class="mt-5">
          <div v-if="currentTab != 'search'">
            <FAQTable :load-faqs="loadAllFaqs" :compactVersion="false" />
          </div>

          <div v-if="currentTab == 'search'">
            <div v-if="searchState == 'Idle'">
              <h4 class="mt-5" v-if="searchTermWas && searchResults.length > 0">
                {{ searchResults.length }} search results for '{{
                  searchTermWas
                }}'
              </h4>
              <FAQTable :load-faqs="searchFaqs" :compactVersion="false" />
              <h5 class="text-center py-3" v-if="searchTermWas && searchResults.length == 0">
                No results for '{{ searchTermWas }}'
              </h5>
            </div>
            <div v-if="searchState == 'Searching'" class="flex items-center justify-center py-20">
              <Spinner :color="'text-black'" :size="10" />
            </div>
            <div v-if="searchState == 'Error'">
              <div class="mt-5 p-6 rounded bg-red-300">
                Something went wrong performing your search, please try again.
                If the problem persists, please contact your support team.
              </div>
            </div>
          </div>
        </div>
        <div v-if="viewState == 'Loading'" class="flex items-center justify-center py-20">
          <spinner :color="'text-black'" :size="10" />
        </div>
      </div>
    </main>
  </div>
</template>

<script>
const PageHeader = () => import("@/components/PageHeader.vue");
const Alert = () => import("@/components/Alert.vue");
const Spinner = () => import("@/components/Spinner.vue");
const Toggle = () => import("@/components/Toggle.vue");
const Panel = () => import("@/components/Panel.vue");
const FaqIcon = () => import("@/assets/icons/faq-icon.vue");
const FAQTable = () => import("@/components/Faqs/FAQTable.vue");

export default {
  name: "Faqs",
  components: {
    PageHeader,
    Alert,
    Spinner,
    Toggle,
    Panel,
    FaqIcon,
    FAQTable
  },
  data() {
    return {
      viewState: "Idle",
      searchState: "Idle",
      currentTab: "new",
      categoryFilter: "",
      searchTerm: "",
      searchTermWas: "",
      searchResults: [],
      showingCreateFaqPanel: false,
      categories: [],
      hasSearched: false
    };
  },
  computed: {
    searchOptions: function () {
      return this.$store.state.serviceTicketOptions;
    }
  },
  async mounted() {
    this.loadCategories();
  },
  methods: {
    selectTabAndClearSearch: function (tabName) {
      this.$nextTick(() => {
        this.currentTab = tabName;
      });
      this.$store.dispatch("setFaqSearchTerm", "");
    },
    async search() {
      if (this.searchTerm != '' || this.categoryFilter != '') {
        this.$store.dispatch("setFaqSearchTerm", this.searchTerm);
        this.$nextTick(() => {
          this.currentTab = "search";
          this.hasSearched = true;
        });
        this.$emit('load-faq');
      }
    },
    async searchFaqs(pageOrderSettings) {
      if (this.searchTerm != '' || this.categoryFilter != '') {
        try {
          const results = await this.faqService.getFAQs(
            pageOrderSettings,
            this.categoryFilter,
            this.searchTerm
          );

          this.searchResults = results.data || [];
          this.searchTermWas = this.searchTerm;
          return results;

        } catch (err) {
          console.error(err);
        }
      }
    },
    async loadAllFaqs(pageOrderSettings) {
      try {
        return await this.faqService.getFAQs(
          pageOrderSettings,
          "",
          ""
        );
      } catch (error) {
        console.error(error);
      } finally {
      }
    },
    loadCategories: async function () {
      let result = await this.faqService.loadCategories();
      this.categories = result;
    },
    openCreateFaq() {
      this.$emit('open-faq-panel');
    },
    clearFilter() {
      this.categoryFilter = "";
      this.searchTerm = "";
      this.searchTermWas = "";
      this.hasSearched = false;
      this.selectTabAndClearSearch('new')
    }
  },
};
</script>
